import { ref } from 'vue';
import { API } from '@/api';
import { defineStore } from 'pinia';

export const useHardLists = defineStore("hardLists", () => {
  const departments = ref([]);
  const roles = ref([]);
  const leadsContactsPositions = ref([]);
  const getLists = async () => {
    API.get("/users/departments").then((res) => {
      departments.value = res.data.data;
    });
    API.get("/users/roles").then((res) => {
      roles.value = res.data.data;
    });
    API.get("/leads/contacts/positions").then((res) => {
      leadsContactsPositions.value = res.data.data;
    });
  };
  return {
    departments,
    roles,
    leadsContactsPositions,
    getLists,
  };
});
