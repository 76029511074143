// import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "default",
      auth: true,
    },
    component: () => import("../modules/kanban/pages/main.vue"),
  },
  {
    path: "/reference",
    name: "reference",
    meta: {
      title: "Reference",
      auth: true,
    },
    component: () => import("../modules/reference/pages/main.vue"),
  },
  {
    path: "/reference/users",
    name: "users",
    meta: {
      title: "Users",
      auth: true,
    },
    component: () => import("../modules/reference/users/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "userCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/users/pages/create.vue"),
      },
      {
        path: ":id",
        name: "userUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/users/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/activities",
    name: "activities",
    meta: {
      title: "Activities",
      auth: true,
    },
    component: () => import("../modules/reference/activities/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "activitiesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/activities/pages/create.vue"),
      },
      {
        path: ":id",
        name: "activitiesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/activities/pages/update.vue"),
      },
    ],
  },
  {
    path: "/leads",
    name: "leads",
    meta: {
      title: "Leads",
      auth: true,
    },
    component: () => import("../modules/lead/pages/main.vue"),
  },
  {
    path: "/leads/create",
    name: "leadsCreate",
    meta: {
      title: "Create",
      auth: true,
    },
    component: () => import("../modules/lead/pages/create.vue"),
  },
  {
    path: "/leads/update/:id",
    name: "leadsUpdate",
    meta: {
      title: "Update",
      auth: true,
    },
    component: () => import("../modules/lead/pages/update.vue"),
  },
  {
    path: "/ui",
    name: "ui",
    meta: {
      title: "ui",
      auth: true,
    },
    component: () => import("../views/ui/ui.vue"),
  },
];
